.principal{
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: aqua;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}