.full-loader{
    position:absolute;
    width:100vw;
    height:100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-size: cover;
}
.text-loader{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color:#fff;
}