.principal{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: aqua;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}
.secondary{
    margin-top: 20px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}