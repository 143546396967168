.sizeable{
    -webkit-transition:all .5s ease; /* Safari y Chrome */
    -moz-transition:all .5s ease; /* Firefox */
    -o-transition:all .5s ease; /* IE 9 */
    -ms-transition:all .5s ease; /* Opera */
  }
  .sizeable:hover{
    -webkit-transform:scale(1.15);
    -moz-transform:scale(1.15);
    -ms-transform:scale(1.15);
    -o-transform:scale(1.15);
    transform:scale(1.15);
  }
.minisizeable{
    -webkit-transition:all .5s ease; /* Safari y Chrome */
    -moz-transition:all .5s ease; /* Firefox */
    -o-transition:all .5s ease; /* IE 9 */
    -ms-transition:all .5s ease; /* Opera */
  }
  .minisizeable:hover{
    color:#38066d;
    -webkit-transform:scale(1.05);
    -moz-transform:scale(1.05);
    -ms-transform:scale(1.05);
    -o-transform:scale(1.05);
    transform:scale(1.05);
  }