.container-btn{
    position:"relative";
    width:"100%"
}
.custom-button{
    border-radius: 5px 25px 25px 5px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    cursor:pointer;
    box-sizing: border-box;
    text-decoration:none;
    font-weight: bold;
    display:inline-block;
    color:#fff;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: medium;
    border: none;
}
.custom-button-disabled{
    background-color: #414042;
    cursor: no-drop;
}
.custom-button-load{
    padding-left: 40px;
    padding-right: 40px;
}
.custom-button:hover{
    transform:scale(1.1);
}