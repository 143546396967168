.navbar-container{
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    background: linear-gradient(90deg, rgb(118, 29, 144) 0%,rgb(56, 6, 109) 100%);
    top: 0;
    left: 0;
    z-index: 2;
}
.fila{
    display: flex;
    flex-direction: row;
}
.columna{
    display: flex;
    flex-direction: column;
}
.navbar-container-logo{
    width:5%;
    color:#6f40ff;
}
.navbar-container-routes{
    width:65%;
    display:flex;
    flex-direction: row;
}
.navbar-logo{
    width:50px;
    height:50px;
    margin-left: 10px;
    cursor: pointer;
}
.navbar-user-container{
    width:30%;
    align-items: right;
    justify-content: flex-end;
}
.user-container{
    display:flex;
    flex-direction: row;
}
.user-img{
    width:50px;
    height:50px;
    border-radius: 50%;
}
.user-info{
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
}
.user-info-text{
    margin-top: 0px;
    color:#fff;
    font-size: 15px;
    font-weight: bold;
}

.navbar-dropdown {
    position: relative;
    display: inline-block;
}
.sizeable{
    -webkit-transition:all .5s ease; /* Safari y Chrome */
    -moz-transition:all .5s ease; /* Firefox */
    -o-transition:all .5s ease; /* IE 9 */
    -ms-transition:all .5s ease; /* Opera */
  }
  .sizeable:hover{
  -webkit-transform:scale(1.15);
  -moz-transform:scale(1.15);
  -ms-transform:scale(1.15);
  -o-transform:scale(1.15);
  transform:scale(1.15);
  }
.minisizeable{
    -webkit-transition:all .5s ease; /* Safari y Chrome */
    -moz-transition:all .5s ease; /* Firefox */
    -o-transition:all .5s ease; /* IE 9 */
    -ms-transition:all .5s ease; /* Opera */
  }
  .minisizeable:hover{
  -webkit-transform:scale(1.05);
  -moz-transform:scale(1.05);
  -ms-transform:scale(1.05);
  -o-transform:scale(1.05);
  transform:scale(1.05);
  }
.navbar-dropdown-content {
    display: none;
    position: absolute;
    width:250px;
    margin-top: 0px;
    padding:15px;
    background: linear-gradient(90deg, rgb(118, 29, 144) 0%,rgb(56, 6, 109) 100%);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
}
/*


*/
.navbar-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.navbar-dropdown-content a:hover {
    background-color: #a1a1a1;
    color:#000;
}

.navbar-dropdown:hover .navbar-dropdown-content {display: block;}

.navbar-dropdown:hover .dropbtn {background-color: #3e8e41;}